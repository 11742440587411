import { useTranslation } from 'react-i18next'
import { TableBody as MuiTableBody, TableRow as MuiTableRow, TableCell } from '@mui/material'
import { Text } from '@controls/Text'
import { TableProps } from '../Table.types'

type TableBodyProps<T> = Pick<TableProps<T>, 'columns' | 'noDataText'>

export const TableEmptyBody = <T,>({ columns, noDataText }: TableBodyProps<T>): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <MuiTableBody>
      <MuiTableRow>
        <TableCell colSpan={columns.length} align="center">
          <Text size="size-24" weight="light">
            {noDataText ?? t('controls.table.messages.no_data_available')}
          </Text>
        </TableCell>
      </MuiTableRow>
    </MuiTableBody>
  )
}
