import { useMemo } from 'react'
import { isValid } from 'date-fns'
import { hasValue } from '@digital-magic/ts-common-utils'
import { TableColumn, TableSortConfig, TableSortDirection } from './Table.types'

type UseTableDataConfig<T> = Readonly<{
  data: ReadonlyArray<T>
  sortConfig: TableSortConfig<T> | undefined
}>

const sortSequence: ReadonlyArray<TableSortDirection | undefined> = ['ASC', 'DESC', undefined]

export const getNextSortDirection = <T>(
  column: TableColumn<T>,
  sortConfig: TableSortConfig<T> | undefined
): TableSortDirection | undefined => {
  const getNextDirIndex = (): number => {
    if (sortConfig?.column !== column.key) return 0

    const currDirIndex = sortSequence.findIndex((d) => sortConfig?.direction === d)

    return (currDirIndex + 1) & sortSequence.length
  }

  return sortSequence[getNextDirIndex()]
}

const transformSortableValue = <T>(val: T): T | string | Date => {
  if (typeof val === 'string') {
    const date = new Date(val)

    if (isValid(date)) {
      return date
    }

    return val.toLowerCase()
  }

  return val
}

const sortTableData = <T>(data: ReadonlyArray<T>, sortConfig: TableSortConfig<T>): ReadonlyArray<T> => {
  const { direction, column } = sortConfig

  return data.concat().sort((a, b) => {
    const aVal = transformSortableValue(a[column])
    const bVal = transformSortableValue(b[column])

    if (aVal < bVal) {
      return direction === 'ASC' ? -1 : 1
    }

    if (aVal > bVal) {
      return direction === 'ASC' ? 1 : -1
    }

    return 0
  })
}

export const useTableData = <T>({ data, sortConfig }: UseTableDataConfig<T>): ReadonlyArray<T> => {
  return useMemo(() => (hasValue(sortConfig) ? sortTableData(data, sortConfig) : data), [data, sortConfig])
}
