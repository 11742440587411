import * as React from 'react'
import { hasValue, isEmpty } from '@digital-magic/ts-common-utils'
import { TableRow as MuiTableRow, TableCell } from '@mui/material'
import { TableColumn } from '../Table.types'

type TableRowProps<T> = Readonly<{
  columns: ReadonlyArray<TableColumn<T>>
  row: T
  rowIndex: number
  onRowClick?: (row: T) => void
  className?: string
}>

export const TableRow = <T,>({
  columns,
  row,
  onRowClick,
  rowIndex,
  className
}: TableRowProps<T>): React.ReactElement => {
  const handleRowClick =
    (row: T) =>
    (e: React.MouseEvent | React.KeyboardEvent): void => {
      if (onRowClick) {
        if (
          e.nativeEvent instanceof MouseEvent ||
          (e.nativeEvent instanceof KeyboardEvent && e.nativeEvent.key === 'Enter')
        ) {
          onRowClick(row)
        }
      }
    }

  return (
    <MuiTableRow
      className={className}
      tabIndex={onRowClick ? 0 : undefined}
      onClick={handleRowClick(row)}
      onKeyDown={handleRowClick(row)}
    >
      {columns.map((col, colIndex) => (
        <TableCell className={col.className} key={`column-${colIndex}`}>
          <>
            {hasValue(col.render) && col.render(row, rowIndex)}
            {isEmpty(col.render) && (hasValue(col.key) ? row[col.key] : '-')}
          </>
        </TableCell>
      ))}
    </MuiTableRow>
  )
}
