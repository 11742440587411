import { TableBody as MuiTableBody } from '@mui/material'
import { TableEmptyBody } from '@controls/Table/TableBody/TableEmptyBody'
import { TableProps } from '../Table.types'
import { TableRow } from '../TableRow'

type TableBodyProps<T> = Pick<TableProps<T>, 'columns' | 'data' | 'noDataText' | 'onRowClick' | 'rowClassName'>

export const TableBody = <T,>({
  columns,
  data,
  noDataText,
  onRowClick,
  rowClassName
}: TableBodyProps<T>): React.ReactElement => {
  if (data.length > 0) {
    return (
      <MuiTableBody>
        {data.map((row, rowIndex) => (
          <TableRow
            onRowClick={onRowClick}
            key={rowIndex}
            columns={columns}
            row={row}
            rowIndex={rowIndex}
            className={rowClassName?.(row)}
          />
        ))}
      </MuiTableBody>
    )
  } else {
    return <TableEmptyBody columns={columns} noDataText={noDataText} />
  }
}
