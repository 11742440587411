import styled, { css } from 'styled-components'
import { TableContainer } from '@mui/material'

export const TableStyled = styled(TableContainer)`
  ${({ theme }) => css`
    overflow: auto;
    position: relative;

    thead {
      position: sticky;
      z-index: 1;
      top: 0;

      th.sortable {
        .sort-icon {
          font-size: 1em;

          opacity: 0.2;
        }

        &.sorted .sort-icon {
          opacity: 1;
        }

        &.sorted.asc .sort-icon {
          transform: scaleY(-1);
        }

        & > button {
          font-weight: bold;
          border: none;
          padding: 0;
          background: transparent;
          cursor: pointer;
        }
      }
    }

    tr {
      height: ${theme.spacing(8)};

      &[tabindex='0']:hover {
        cursor: pointer;
        background: ${theme.colors.grey200};
      }
    }

    tbody {
      tr:nth-child(odd) {
        background: ${theme.colors.grey100};
      }

      tr:nth-child(even) {
        background: ${theme.colors.white};
      }
    }

    th {
      font-size: ${theme.global.typography.size['size-14']};
      font-weight: bold;
      white-space: nowrap;
    }

    td {
      font-size: ${theme.global.typography.size['size-16']};
      padding-top: 0;
      padding-bottom: 0;
    }

    tr.deleted-row td {
      color: ${theme.colors.error};
    }
  `}
`
