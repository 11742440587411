import * as React from 'react'
import { hasValue } from '@digital-magic/ts-common-utils'
import classNames from 'classnames'
import { Sort } from '@mui/icons-material'
import { Box, Fade, Table as MuiTable, TableRow as MuiTableRow, TableCell, TableHead } from '@mui/material'
import { TableColumn, TableProps, TableSortConfig } from '.'
import { TableStyled } from './Table.styles'
import { getNextSortDirection, useTableData } from './Table.utils'
import { TableBody } from './TableBody'

export type Props<T> = TableProps<T> & {
  initialSortConfig?: TableSortConfig<T>
}

export const Table = <T,>({
  columns,
  className,
  noDataText,
  onRowClick,
  rowClassName,
  initialSortConfig,
  ...props
}: Props<T>): React.ReactElement => {
  const [sortConfig, setSortConfig] = React.useState<TableSortConfig<T> | undefined>(initialSortConfig)

  const data = useTableData({
    sortConfig,
    data: props.data
  })

  const handleSortClick = React.useCallback(
    (column: TableColumn<T>) => (): void => {
      const nextDir = getNextSortDirection(column, sortConfig)

      const nextConfig: TableSortConfig<T> | undefined =
        hasValue(nextDir) && hasValue(column.key) ? { column: column.key, direction: nextDir } : undefined

      setSortConfig(nextConfig)
    },
    [sortConfig]
  )

  const tableHead = React.useMemo(() => {
    return (
      <MuiTableRow>
        {columns.map((c, i) => (
          <TableCell
            width={c.width}
            style={{ minWidth: c.minWidth }}
            key={i}
            className={classNames(
              c.className,
              { sortable: c.sortable, sorted: c.key === sortConfig?.column },
              sortConfig?.direction.toLowerCase()
            )}
          >
            <Box
              component={c.sortable ? 'button' : 'div'}
              display="inline-flex"
              alignItems="center"
              fontFamily="inherit"
              fontSize="inherit"
              columnGap={1}
              onClick={c.sortable ? handleSortClick(c) : undefined}
            >
              <span>{c.title}</span>
              {c.sortable && <Sort className="sort-icon" />}
            </Box>
          </TableCell>
        ))}
      </MuiTableRow>
    )
  }, [columns, handleSortClick, sortConfig])

  return (
    <TableStyled className={className}>
      <Fade in>
        <MuiTable>
          <TableHead>{tableHead}</TableHead>
          <TableBody
            columns={columns}
            data={data}
            onRowClick={onRowClick}
            rowClassName={rowClassName}
            noDataText={noDataText}
          />
        </MuiTable>
      </Fade>
    </TableStyled>
  )
}
